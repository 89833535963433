import React from 'react';
import './title.scss';

interface Props {
  title: string;
  bold?: boolean;
}

export default function Title({ title, bold = false }: Props) {
  return <h1 className={`ant-col ant-col-20 pageTitle ${bold ? 'pageTitle__bold' : ''}`}>{title}</h1>;
}
