import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { State, useConfigurationStore } from '../../store';
import { useQuery } from 'react-query';
import './indicatorView.shared.scss';
import { fetchIndicatorDynamics } from '../../queries';
import DynamicsChart from '../../shared/components/DynamicsChart';
import { ENABLE_PARTNERSHIPS } from '../../config';
import CopyWarningIcon from '../../shared/components/CopyWarningIcon/CopyWarningIcon';
import { Indicator } from '../../shared/interfaces/indicators.interface';

interface Props {
  indicator?: Indicator;
}

const IndicatorDynamicsChart: ({ indicator }: Props) => JSX.Element = ({ indicator }: Props) => {
  const { id } = useParams<{ id: string }>();

  const territory = useConfigurationStore((state: State) => state.territory);
  const year = useConfigurationStore((state: State) => state.year);
  const comparisonTerritories = useConfigurationStore((state: State) => state.comparisonTerritories);
  const { isLoading, data } = useQuery(
    ['indicatorDynamics', id, territory, comparisonTerritories, year],
    fetchIndicatorDynamics,
    {
      enabled: !!territory && !!id,
    },
  );

  const titleText = `Pozycja ${ENABLE_PARTNERSHIPS ? 'gminy w partnerstwie' : 'gminy w grupie porównawczej'}`;

  const title = useMemo(
    () => (
      <React.Fragment>
        <p>{titleText}</p>
        {data?.includes_copied_values ? <CopyWarningIcon /> : null}
      </React.Fragment>
    ),
    [data],
  );

  return (
    <DynamicsChart
      title={title}
      data={data?.data}
      isLoading={isLoading}
      titleText={titleText}
      showCompGroup={true}
      showTeryt
    />
  );
};

export default IndicatorDynamicsChart;
