import React from 'react';
import './styles/pageheader.scss';
import { HomeFilled, MenuOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Col, Row, Select, Space, Typography } from 'antd';
import { useHistory } from 'react-router-dom';
import AreaSelect from './AreaSelect';
import SubAreaSelect from './SubAreaSelect';
import IndicatorSelect from './IndicatorSelect';
import { Indicator, IndicatorArea, IndicatorSubarea } from '../../../shared/interfaces/indicators.interface';
import { HOME_ROUTE } from '../../../routes';
import { useConfigurationStore } from '../../../store';
import { availableYears, ENABLE_PARTNERSHIPS, ENABLE_REPORT_REQUESTS } from '../../../config';
import ReportRequestButton from './ReportRequestButton';

const { Option } = Select;
const { Text } = Typography;

type Props = {
  visible: boolean;
  setVisible(value: boolean): void;
};

const PageHeader: React.FC<Props> = ({ visible, setVisible }) => {
  const [area, setArea] = React.useState<IndicatorArea | undefined>(undefined);
  const [subArea, setSubArea] = React.useState<IndicatorSubarea | undefined>(undefined);
  const [indicator, setIndicator] = React.useState<Indicator | undefined>(undefined);
  const [currentActivePage, setCurrentActivePage] = React.useState('home');
  const history = useHistory();

  const territory = useConfigurationStore((state) => state.territory);
  const partnership = useConfigurationStore((state) => state.partnership);
  const setYear = useConfigurationStore((state) => state.setYear);

  React.useEffect(() => {
    history.listen(({ pathname }) => {
      if (pathname === '/') setCurrentActivePage('home');
      if (pathname.includes('/areas/')) setCurrentActivePage('area');
      if (pathname.includes('/subareas/')) setCurrentActivePage('subArea');
      if (pathname.includes('/indicators/')) setCurrentActivePage('indicator');
    });
  }, [history]);

  const clearFields = (arr: string[]): void => {
    if (arr.includes('subArea')) {
      setSubArea(undefined);
    }
    if (arr.includes('indicator')) {
      setIndicator(undefined);
    }
  };

  const isLabelBold = {
    area: ['area', 'subArea', 'indicator'],
    subArea: ['subArea', 'indicator'],
    indicator: ['indicator'],
  };

  const getTitle = () => {
    if (ENABLE_PARTNERSHIPS) {
      return (
        <>
          {territory && <Text strong>{territory.label}</Text>}
          {partnership && <Text type="secondary">{partnership.label}</Text>}
        </>
      );
    }

    return (
      <>
        {territory && <Text strong>{territory.label}</Text>}
        {territory && territory.comparison_group && <Text type="secondary">{territory.comparison_group.name}</Text>}
      </>
    );
  };

  return (
    <Row className="page-header">
      <Col span={24}>
        <div className="top-part">
          <div className="sider-controller">
            <MenuOutlined onClick={() => setVisible(!visible)} />
          </div>
          <Space>
            <div className="year-select">
              <Select defaultValue={availableYears[0]} style={{ width: 80 }} onChange={setYear}>
                {availableYears.map((year) => (
                  <Option key={year} value={year}>
                    {year}
                  </Option>
                ))}
              </Select>
            </div>
            {getTitle()}
            {ENABLE_REPORT_REQUESTS && <ReportRequestButton />}
          </Space>
        </div>
      </Col>
      <Col span={24}>
        <div className="level-select">
          <div className={`home-button ${currentActivePage === 'home' ? 'active' : ''}`}>
            <Button
              icon={<HomeFilled />}
              onClick={() => {
                history.push(HOME_ROUTE);
                window.scrollTo(0, 0);
              }}
            />
          </div>

          <div className="selection-row-container">
            <Row gutter={[16, 0]}>
              <Col span={8}>
                <div className="selection-container">
                  <span className="label">OBSZAR</span>
                  <AreaSelect
                    onSelect={(selected) => {
                      setArea(selected);
                      setSubArea(undefined);
                      setIndicator(undefined);
                    }}
                    active={currentActivePage === 'area'}
                    labelBold={isLabelBold.area.includes(currentActivePage)}
                    clearFields={clearFields}
                  />
                  <Button
                    className="selection-button"
                    icon={<RightOutlined />}
                    onClick={() => {
                      area && history.push(`/areas/${area.key}`);
                      window.scrollTo(0, 0);
                    }}
                    disabled={typeof area === 'undefined'}
                  />
                </div>
              </Col>
              <Col span={8}>
                <div className="selection-container">
                  <span className="label">PODOBSZAR</span>
                  <SubAreaSelect
                    area={area}
                    subArea={subArea}
                    active={currentActivePage === 'subArea'}
                    labelBold={isLabelBold.subArea.includes(currentActivePage)}
                    onSelect={(selected) => {
                      setSubArea(selected);
                      setIndicator(undefined);
                    }}
                    clearFields={clearFields}
                  />
                  <Button
                    className="selection-button"
                    icon={<RightOutlined />}
                    onClick={() => {
                      subArea && history.push(`/subareas/${subArea.key}`);
                      window.scrollTo(0, 0);
                    }}
                    disabled={typeof subArea === 'undefined'}
                  />
                </div>
              </Col>
              <Col span={8}>
                <div className="selection-container">
                  <span className="label">WSKAŹNIK</span>
                  <IndicatorSelect
                    subArea={subArea}
                    onSelect={(selectedIndicator) => {
                      selectedIndicator && history.push(`/indicators/${selectedIndicator.key}`);
                      setIndicator(selectedIndicator);
                    }}
                    indicator={indicator}
                    active={currentActivePage === 'indicator'}
                    labelBold={isLabelBold.indicator.includes(currentActivePage)}
                  />
                  <Button
                    className="selection-button"
                    icon={<RightOutlined />}
                    onClick={() => {
                      indicator && history.push(`/indicators/${indicator.key}`);
                      window.scrollTo(0, 0);
                    }}
                    disabled={typeof indicator === 'undefined'}
                  />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default PageHeader;
